import React from "react";

const Agreement = () => {

    const Link = ({link , name}) => {
        return <a href={link} rel="noreferrer" target="_blank">{name}</a>;
    }

    return (
        <div>
             I have read and agree to the <Link link='https://www.melioeducation.com/terms' name='Terms & Conditions.'/>
        </div>
    )
}

export default Agreement;