import React, {useState} from 'react';
import useResource from './useResource';


export const PRODUCT_IDS = {
  oTo: '0124L0000004vZRQAY',
  aP: '0124L0000004vZQQAY',
  upp: '0124L000000PnBXQA0'
}

export function getProductTypes(products = []) {

  const productTypes = new Map()

  products.forEach((product) => {
    productTypes.set(product.RecordType.Id, product.RecordType.Name )
  })

  const types = Array.from(productTypes ,([name, value]) => ({name, value}))
  const prices = types.map(({name, value}) => {
    const records = products.filter((product) => {
      return product.RecordType.Id === name
    }).map((product) => product.PricebookEntries.records[0])
    const min = records.reduce((accum, record) => {
      if (!accum) return record.UnitPrice
      if (record.UnitPrice < accum) return record.UnitPrice
      return accum
    }, 100000)
    return {
      id: name,
      name: value, 
      min,
    }
  })

  return prices

}

export function getProductType(productTypes, name) {
  return productTypes.find((p) => p.name === name)
}

export function getProductsByType(productTypes, name) {
  return productTypes.filter((p) => p.RecordType.Name === name)
}


export function getMinForProductName(productTypes, name) {
  const product = getProductType(productTypes, name)
  return product && product.min
}
const arrayUniqueByKey = (array, key) => [...new Map(array.map(item =>
  [item[key], item])).values()];


export function getDurationOptions(products, subject) {
  
  const durationProducts = products.filter((product) => 
    product.RecordType.Id === PRODUCT_IDS.oTo
  )
  if (!subject) {
    const durationPrices = arrayUniqueByKey(durationProducts, 'Duration__c')
    return durationPrices
  }
  return durationProducts.filter((product) => 
    product.Subject_c === subject
  )
}

function useProducts(setError) {
  const [products, setProducts] = useState()
  const [loading, setLoading] = useState()
  useResource('Products', products, setProducts, setLoading, loading, setError)
  const productTypes = getProductTypes(products)
  const productsByType = productTypes.reduce((accum, pt) => {
    accum[pt.id] = getProductsByType(products, pt.name)
    return accum
  }, {})
  return {
    products,
    setProducts,
    productTypes,
    productsByType,
  }
}
export default useProducts;
