import React, { useState } from 'react';
import Layout from '../../../../components/Layout'
import TopBar from '../../../../components/GeneratedTopBarNavigation'
import Footer from '../../../../components/GeneratedFooter'
import Form from '../../../../services/salesforce/components/forms/student/Apply';

const ApplyForm = () => (
    <Layout>
      <TopBar />

      <Form />
      <Footer />

    </Layout>
)

export default ApplyForm;
