import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {makeStyles} from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography } from "@material-ui/core";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 400,
            width: 300,
        },
    },
};

const Country = (props) => {
    const { countries, error, getCountry, setError, setField, fieldValue, loading=false } = props;

    const handleChoose = (event) => {
        const value = event.target.value;
        setField('country', value);
        setError(false);
    }

    return (
        <>
            {loading ? (
            <Skeleton variant="text" width="100%" animation="pulse"><FormControl fullWidth>
                <InputLabel>.</InputLabel>
                <Select fullWidth />
            </FormControl></Skeleton>)
            :
            (countries && <FormControl required fullWidth>
                <InputLabel error={error} required>Country</InputLabel>
                <Select fullWidth error={error} value={fieldValue('country')} name="country" onChange={handleChoose} MenuProps={MenuProps}>
                    { countries.length === 0 ? <MenuItem disabled value=""><em>Something went wrong!</em></MenuItem>
                        : countries.map((c, i) => {
                            return <MenuItem key={c + i} value={c}>{c}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>)}
        </>
    )
}

export default Country;