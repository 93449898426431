import React, {useState} from 'react';
import useResource from './useResource';
import { PRODUCT_IDS, getDurationOptions } from './useProducts'

function useProductSchemas(setError) {
  const [productSchemas, setProductSchemas] = useState()
  const [loading, setLoading] = useState()
  useResource('ProductDurationSchema', productSchemas, setProductSchemas, setLoading, loading, setError)
  const filtered = ['50 hours', '40 hours']
  const filterPrices = (hours) => !filtered.includes(hours);
  const mapPrices = (products, durations) => (hours) => {
    const entry = durations.find((duration) => 
      duration.Duration__c === hours
    )
    const price = entry && entry.PricebookEntries.records[0].UnitPrice || ''
    switch (hours) {
      case '10 hours':
        return `10 hours of teaching (1 package), £ ${price}`;
      case '20 hours':
        return `20 hours of teaching (2 packages), £ ${price}`;
      case '30 hours':
        return `30 hours of teaching (3 packages),  £ ${price}`;
      case '60 hours':
        return `60 hours of teaching (6 packages), £ ${price}`;
      }
  }
  const onlyHours = (prod) => prod.substr(0, 8)
  const addPrices = (ps=[], products=[]) => {
    const durations = getDurationOptions(products)
    return ps.filter(filterPrices).map(mapPrices(products, durations))
  }

  return {
    productSchemas,
    setProductSchemas,
    addPrices,
    onlyHours,
  }
}
export default useProductSchemas;
