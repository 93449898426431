import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import FormLabel from '@material-ui/core/FormLabel'
import Divider from '@material-ui/core/Divider'
import Skeleton from '@material-ui/lab/Skeleton'
import { navigate } from 'gatsby'

import PhoneInput from '../../PhoneInput'
import Country from '../../Country'
import Parent from '../../Parent'
import Products from '../../Products'
import Price from '../../Price'
import Payment from '../../Payment'
import UserAgreement from '../../UserAgreement'
import useCountries from '../../../../salesforce/hooks/useCountries'
import useProducts from '../../../../salesforce/hooks/useProducts'
import Summary from '../../Summary'
import useProductSchemas from '../../../../salesforce/hooks/useProductSchemas'
import {
    calcAge,
    isOld,
    isYoung,
    checkDateOfBirth,
    isOut,
} from '../../../../salesforce/lib/validations/dob'
import validateEmail from '../../../../salesforce/lib/validations/email'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#8BD2C1',
    },
    container: {
        marginTop: 128,
    },
    buttonPrimary: {
        width: '100%',
        height: '72px',
        borderRadius: '0.938rem',
        color: theme.palette.text.white,
        fontSize: '1.25rem',
        margin: '50px 0px',
        background: 'linear-gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
        '&:hover': {
            background:
                'linear-gradient(138.25deg, rgba(255, 175, 117, 0.5) 7%, rgba(244, 95, 0, 0.5) 95.28%)',
        },
        [theme.breakpoints.down('sm')]: {
            height: '2.5rem',
            fontSize: '0.875rem',
            lineHeight: '1.125rem',
            margin: '24px 0px',
        },
    },
}))

const createField = (name, initialValue) => ({
    [name]: {
        value: initialValue,
        error: false,
        dirty: false,
        previousValue: initialValue,
    },
})

const secureKeyBody = {
    key: process.env.GATSBY_SALESFORCE_KEY,
}

const Form = (props) => {
    const classes = useStyles()
    const [formState, setFormState] = useState({
        ...createField('product', ''),
        ...createField('firstName', ''),
        ...createField('lastName', ''),
        ...createField('selectedProduct', ''),
        ...createField('productType', ''),
        ...createField('why', ''),
        ...createField('duration', ''),
        ...createField('email', ''),
        ...createField('school', ''),
        ...createField('country', ''),
        ...createField('phone', ''),
        ...createField('dob', ''),
        ...createField('pgFullName', ''),
        ...createField('pgPhone', ''),
        ...createField('pgEmail', ''),
        ...createField('userAgreement', false),
        ...createField('pay', null),
    })

    const [loading, setLoading] = useState(false)
    const [young, setYoung] = useState(false)
    const [old, setOld] = useState(false)
    const [out, setOut] = useState(false)
    const [age, setAge] = useState()
    const [openAlert, setOpenAlert] = useState(false)
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false)
    const { countries, setCountries } = useCountries((val) =>
        setError('country', val)
    )
    const { products, setProducts, productTypes, productsByType } = useProducts(
        (val) => setError('products', val)
    )

    const { productSchemas, setProductSchemas, addPrices, onlyHours } =
        useProductSchemas((val) => setError('productSchemas', val))
    const [type, setType] = useState({ oTo: false, aP: false, upp: false })

    const setInitialValue = (field, initialValue) => {
        setFormState((prev) => {
            const next = {
                ...prev,
                [field]: {
                    ...prev[field],
                    initialValue,
                },
            }
            return next
        })
    }
    const setField = (field, value) => {
        if (!formState[field]) {
            console.error('Field', field, 'not initialized')
        }
        setFormState((prev) => {
            const next = {
                ...prev,
                [field]: {
                    value,
                    previousValue: prev[field].value,
                    dirty: Boolean(prev[field].initialValue !== value),
                },
            }
            return next
        })
    }

    const setError = (field, error) => {
        setFormState((prev) => {
            const next = {
                ...prev,
                [field]: {
                    ...prev[field],
                    error,
                },
            }
            return next
        })
    }

    const errorFor = (field) => {
        return (formState[field] && formState[field].error) || null
    }

    const fieldValue = (field, string = true) => {
        return rawValue(field)
    }

    const rawValue = (field) => {
        return formState[field] && formState[field].value
    }

    const changed = (field) => {
        if (formState[field]) return formState[field].dirty
        return false
    }

    const doPost = () => {
        setOpenBackdrop(true)
        const body = {
            firstName: fieldValue('firstName'),
            lastName: fieldValue('lastName'),
            phone: fieldValue('phone'),
            email: fieldValue('email'),
            school: fieldValue('school'),
            country: fieldValue('country'),
            duration: onlyHours(fieldValue('duration')),
            dob: fieldValue('dob'),
            pgFullName: fieldValue('pgFullName'),
            pgPhone: fieldValue('pgPhone'),
            pgEmail: fieldValue('pgEmail'),
            nowPay: Boolean(fieldValue('pay') === 'now'),
            userAgreement: fieldValue('userAgreement'),
            selectedCourses: [{ id: fieldValue('selectedProduct').Id }],
            key: secureKeyBody.key,
        }

        fetch(
            'https://melioeducation.my.salesforce-sites.com/developerData/services/apexrest/ApplicationForm/',
            {
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            }
        )
            .then((result) => {
                if (result.status !== 200) {
                    alert('Something went wrong')
                } else {
                    return result.json().then((data) => {
                        setOpenBackdrop(false)
                        setOpenSuccess(true)
                        return data
                    })
                }
            })
            .then((result) => {
                if (fieldValue('pay') === 'later') {
                    if (fieldValue('productType') === 'oTo') {
                        window.location.assign(
                            'https://welcome.melioeducation.com/thank-you/1-to-1-sf'
                        )
                    } else if (fieldValue('productType') === 'upp') {
                        window.location.assign(
                            'https://welcome.melioeducation.com/thank-you/upp'
                        )
                    } else {
                        window.location.assign(
                            'https://welcome.melioeducation.com/thank-you/aoc-sf'
                        )
                    }
                } else {
                    if (fieldValue('productType') === 'oTo') {
                        window.location.assign(
                            `https://melioeducation.my.salesforce-sites.com/developerData/PaymentPage?Id=${result.Id}`
                        )
                    } else if (fieldValue('productType') === 'upp') {
                        window.location.assign(
                            `https://melioeducation.my.salesforce-sites.com/developerData/PaymentPage?Id=${result.Id}`
                        )
                    } else {
                        window.location.assign(
                            `https://melioeducation.my.salesforce-sites.com/developerData/PaymentPage?Id=${result.Id}`
                        )
                    }
                }
            })
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') return
        setOpenAlert(false)
        setOpenSuccess(false)
    }
    const handleError = (field) => (e) => {
        const value = e.target.value
        const nameField = field
        value === '' ? setError(nameField, true) : setError(nameField, false)
        if (nameField === 'email' || nameField === 'pgEmail')
            validateEmail(value)
    }

    const updateAge = useCallback(
        (setFieldValue, formState) => (val) => {
            if (val.length > 10) return
            const ageVal = calcAge(val)
            const youngVal = isYoung(ageVal)
            const oldVal = isOld(ageVal)
            const isOutVal = isOut(ageVal)
            setOut(isOutVal)
            setOld(oldVal)
            setYoung(youngVal)
            setAge(ageVal)
            setFieldValue('dob', val)
        },
        []
    )

    const handleSubmit = (event) => {
        event.preventDefault()
        const target = event.target
        const errors = {}
        for (const tE of target) {
            const nameField = tE.name
            const value = tE.value
            const isValid = tE.validity.valid
            if (
                nameField === 'upp' ||
                nameField === 'aP' ||
                nameField === 'oTo' ||
                nameField === 'payLater' ||
                nameField === ''
            )
                continue
            if (
                value === '' &&
                nameField !== 'payNow' &&
                nameField !== 'userAgreement'
            ) {
                setError(nameField, true)
                errors[nameField] = true
            } else if (nameField === 'phone' && value.length <= 5) {
                setError(nameField, true)
                errors[nameField] = true
            } else if (nameField === 'pgPhone' && value.length <= 5) {
                setError(nameField, true)
                errors[nameField] = true
            } else if (
                nameField === 'pay' &&
                (!fieldValue('pay') || !isValid)
            ) {
                setError(nameField, true)
                errors[nameField] = true
            } else if (nameField === 'userAgreement' && !isValid) {
                setError(nameField, true)
                errors[nameField] = true
            }
        }
        if (fieldValue('product') === '') {
            console.log('fieldValue(product)', fieldValue('product'))
            setError('product', true)
            errors['product'] = true
        }
        const results = Object.keys(errors).reduce((prev, key) => {
            if (errors[key]) {
                console.log('error', key)
            }
            return prev + (errors[key] ? 1 : 0)
        }, 0)
        if (results === 0) {
            doPost()
        } else {
            setOpenAlert(true)
        }
    }

    return (
        <Container maxWidth="sm" className={classes.container}>
            <form onSubmit={handleSubmit} noValidate>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h2" component="h1" gutterBottom>
                            Student application form
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            inputProps={{ maxLength: 50 }}
                            error={errorFor('firstName')}
                            name="firstName"
                            required
                            label="What's your first name?"
                            value={fieldValue('firstName')}
                            placeholder="Enter your first name"
                            helperText={
                                errorFor('firstName')
                                    ? 'Please fill in this field!'
                                    : ''
                            }
                            onChange={(e) =>
                                setField('firstName', e.target.value)
                            }
                            onBlur={handleError('firstName')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            inputProps={{ maxLength: 50 }}
                            required
                            label="And your last name?"
                            error={errorFor('lastName')}
                            value={fieldValue('lastName')}
                            placeholder="Enter your last name"
                            name="lastName"
                            helperText={
                                errorFor('lastName')
                                    ? 'Please fill in this field!'
                                    : ''
                            }
                            onChange={(e) =>
                                setField('lastName', e.target.value)
                            }
                            onBlur={handleError('lastName')}
                            fullWidth
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            fullWidth
                            inputProps={{ maxLength: 80 }}
                            required
                            label="What's your email address?"
                            error={errorFor('email')}
                            name="email"
                            type="email"
                            value={fieldValue('email')}
                            placeholder="Enter your email address"
                            onChange={(e) => setField('email', e.target.value)}
                            helperText={
                                errorFor('email')
                                    ? 'Invalid email address!'
                                    : 'Please keep an eye on your inbox for updated course information.'
                            }
                            onBlur={handleError('email')}
                        />
                    </Grid>
                    <Grid item>
                        <PhoneInput
                            getPhone={(val) => setField('phone', val)}
                            helpText="Our team will need to call you in order to confirm your place on a course."
                            name="phone"
                            error={errorFor('phone')}
                            setError={(val) => setError('phone', val)}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            inputProps={{ maxLength: 250 }}
                            required
                            label="Current School"
                            name="school"
                            error={errorFor('school')}
                            value={fieldValue('school')}
                            placeholder="Enter your school"
                            onChange={(e) => setField('school', e.target.value)}
                            helperText={
                                errorFor('school')
                                    ? 'Please fill in this field!'
                                    : 'If you have graduated, please list your most recent school or university.'
                            }
                            onBlur={handleError('school')}
                        />
                    </Grid>
                    <Grid item>
                        <Country
                            setField={setField}
                            fieldValue={fieldValue}
                            countries={countries}
                            error={errorFor('country')}
                            setError={(val) => setError('country', val)}
                            loading={loading}
                            required
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            required
                            label="Birthday"
                            type="date"
                            error={errorFor('dob')}
                            name="dob"
                            value={fieldValue('dob')}
                            helperText={
                                errorFor('dob')
                                    ? 'Please fill in this field!'
                                    : ''
                            }
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                updateAge(setField)(e.target.value, formState)
                            }
                            onBlur={() =>
                                changed('dob')
                                    ? checkDateOfBirth(
                                          fieldValue('dob'),
                                          (err) => setError('dob', err)
                                      )
                                    : () => {}
                            }
                        />
                    </Grid>
                    <Grid item>
                        <Parent
                            setField={setField}
                            fieldValue={fieldValue}
                            setError={setError}
                            errorFor={errorFor}
                            handleError={handleError}
                            young={young}
                        />
                    </Grid>
                    {!old && (
                        <>
                            <Grid item>
                                <Products
                                    selectedProduct={fieldValue(
                                        'selectedProduct'
                                    )}
                                    setSelectedProduct={(val) =>
                                        setField('selectedProduct', val)
                                    }
                                    products={products}
                                    productSchemas={addPrices(
                                        productSchemas,
                                        products
                                    )}
                                    setProductType={(val) =>
                                        setField('productType', val)
                                    }
                                    setDuration={(val) =>
                                        setField('duration', val)
                                    }
                                    duration={fieldValue('duration')}
                                    errorFor={errorFor}
                                    setError={setError}
                                    fieldValue={fieldValue}
                                    setField={setField}
                                    type={type}
                                    setType={setType}
                                    productTypes={productTypes}
                                    formState={formState}
                                />
                            </Grid>
                            <Grid item>
                                <Price
                                    selectedProduct={fieldValue(
                                        'selectedProduct'
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <UserAgreement
                                    setField={setField}
                                    setError={setError}
                                    fieldValue={fieldValue}
                                    errorFor={errorFor}
                                />
                            </Grid>
                            <Grid item>
                                <Payment
                                    setField={setField}
                                    setError={setError}
                                    fieldValue={fieldValue}
                                    errorFor={errorFor}
                                />
                            </Grid>
                            <Grid item>
                                <Summary
                                    selectedProduct={fieldValue(
                                        'selectedProduct'
                                    )}
                                    fieldValue={fieldValue}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item>
                        {!old && (
                            <Button
                                className={classes.buttonPrimary}
                                type="submit"
                            >
                                Submit
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        {(out || old) && (
                            <Alert variant="outlined" severity="info">
                                Sorry we can’t offer you a course at the moment!
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            </form>
            <Snackbar
                open={openAlert}
                autoHideDuration={5000}
                onClose={handleCloseAlert}
            >
                <Alert onClose={handleCloseAlert} severity="warning">
                    Please fill in all required fields!
                </Alert>
            </Snackbar>
            <Snackbar
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert onClose={handleCloseAlert} severity="success">
                    Successfully created
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}

export default Form
