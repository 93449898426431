import React, {useEffect, useState} from "react";
import { InputLabel } from "@material-ui/core";

const Price = ({products, selectedProduct}) => {
    if (selectedProduct !== '') {
        return (
            <div>
                {/* <InputLabel> Thank you for your application. The cost of your course will be: {selectedProduct.price} </InputLabel> */}
                {/* <InputLabel> Thank you for your application.</InputLabel> */}
            </div>
        )
    }
    return null;
}

export default Price;