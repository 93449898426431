import React, {useState, useCallback} from "react";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";

import PhoneInput from "./PhoneInput";
import validateEmail from "../lib/validations/email";

const Parent = (props) =>{
    const {setField, fieldValue, setError, errorFor, handleError, young} = props;

    const validEmail = (value) => {
        const isValid = validateEmail(value);
        setError('pgEmail', !isValid);
    }

    const setFormField = useCallback((field) => {
        setField(field)
    }, [setField])

    if (!young) return null;
    return (
        <>
        <Box p={1} marginBottom={2} marginTop={2} paddingTop={2} paddingBottom={2}>
        <Typography variant="h4" component="h4">Parent/Guardian</Typography>
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <TextField
                    fullWidth
                    required 
                    error={errorFor('pgFullName')}
                    label="Parent/Guardian full name"
                    name="pgFullName"
                    inputProps={{maxLength: 80}}
                    value={fieldValue('pgFullName')}
                    placeholder='Enter your full name'
                    onChange={(e) => setField('pgFullName', e.target.value)}
                    onBlur={handleError('pgFullName')}
                    helperText={errorFor('pgFullName') ? 'Please fill in this field!' : ''}
                /> 

            </Grid>
            <Grid item>
            <PhoneInput
                label="What's their phone number?"
                getPhone={(val) => setField('phone', val)}
                name="pgPhone"
                error={errorFor('pgPhone')}
                setError={(val) => setError('pgPhone', val)}
            />
            </Grid>
            <Grid item>
            <TextField 
                fullWidth
                inputProps={{maxLength: 80}}
                required
                label="Parent/Guardian email address" 
                error={errorFor('pgEmail')}
                type="email"
                value={fieldValue('pgEmail')}
                placeholder="email address"
                onChange={(e) => setField('pgEmail', e.target.value)}
                helperText={errorFor('pgEmail') ? 'Invalid email address!' : ''}
                onBlur={handleError('pgEmail')}
                name="pgEmail"
            /> 
            </Grid>
        </Grid>

        </Box>
        </>

    );
}
export default Parent;