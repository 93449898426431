import React, {useState} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const Payment = props => {
  const { fieldValue, setField, setError, errorFor } = props;
  
  const required = fieldValue('pay') === undefined

  const handleRadioChange = (event) => {
    setField('pay', event.target.value);
    setError('pay', false);
  };

  const helperText = errorFor('pay') ? '' : ''

  return (
    <Box marginBottom={2} marginTop={2} paddingTop={2} paddingBottom={2}>
      <FormLabel error={errorFor('pay')} required component="legend">
        Would you like to submit your payment at this time to reserve your place?
      </FormLabel>

    <FormControl component="fieldset" error={errorFor("pay")} required>
      <RadioGroup name="pay" value={fieldValue('pay')} onChange={handleRadioChange}>
        <FormGroup row={true}>
          <FormControlLabel control={
            <Radio
              value="now"
              required={required}
            />}
            label="Yes, I'll reserve my place now"/>
          <FormControlLabel 
            control={
              <Radio
                value="later"
                required={required}
              />} 
              label="No, I'll pay later"/>
        </FormGroup> 
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
    </Box>
    
  )
}

export default Payment;