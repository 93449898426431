import React, { useState } from 'react';
import useResource from './useResource';

function useCountries(setError) {
  const [countries, setCountries] = useState()
  const [loading, setLoading] = useState()
  useResource('CountriesList', countries, setCountries, setLoading, loading, setError)
  return {
    countries,
    setCountries,
  }
}
export default useCountries;