import React, {useState, useCallback, useEffect} from "react";
import { useTheme } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: 200,
            // width: 300,
        },
    },
};

const ProductList = props => {
    const theme = useTheme();
    const [displayText, setDisplayText] = useState(false)
    const { pack, products, type, productSchemas, setSelectedProduct, selectedProduct, onSelectionModelChange } = props;
    let productsProcessed = [];
    
    const getRows = useCallback(() => {
        const duration = pack;
        for (const product of products) {
            if (type.oTo) {
                if (product.RecordType.Name === 'One-to-One'){
                    const packageArr = product.Duration__c.split(' ')[0];
                    const timeValue = pack.split(' ')[0]
                    const index = packageArr.indexOf(duration);
                    if (timeValue === packageArr) {
                        productsProcessed.push({
                            Id: product.Id,
                            label: `${product.Subject__c}`,
                            Subject__c: product.Subject__c,
                            price: product.PricebookEntries.records[0].UnitPrice,
                        });
                    }
                }
            } else if (type.aP) {
                if (product.RecordType.Name === 'Academic'){
                    productsProcessed.push({
                        Id: product.Id,
                        Subject__c: product.Subject__c,
                        label: `${product.Subject__c}`,
                        price: product.PricebookEntries.records[0].UnitPrice,
                    });
                }
            } else if (type.upp) {

                if (product.RecordType.Name === 'University Preparation Programme'){
                    productsProcessed.push({
                        Id: product.Id,
                        Subject__c: product.Subject__c,
                        label: `${product.Subject__c}`,
                        price: product.PricebookEntries.records[0].UnitPrice,
                    });
                }
            }
        }
        return productsProcessed;
    }, [products, type])


    const handlerChange = (event) => {
        const selPr = event.target.value;
        onSelectionModelChange(selPr);
        setDisplayText(true)
    }

    // useEffect(() => {
    //     if (type) {
    //         getRows()
    //     }
    // }, [type])

    const list =  getRows()

    return (
        <>
            <Grid item>      
                <FormControl required fullWidth>
                    <InputLabel required>
                        Which subject would you like to study?
                    </InputLabel>
                    <Select 
                        multiple={false}
                        displayEmpty
                        value={selectedProduct}
                        input={<Input />}
                        onChange={handlerChange}
                        MenuProps={MenuProps}
                        renderValue={(selected) => (selected && selected.label) || ''}
                    >

                        {list.length === 0 ? <MenuItem disabled value=""><em>No subject</em></MenuItem>
                            : list.map((prod, index) => {
                                return (
                                <MenuItem key={`${prod.Id}-${index}`} value={prod}>{prod.label}</MenuItem>
                            )})
                        }
                    </Select>
                </FormControl>
                {displayText &&
                    <Box padding={2}>
                        <Typography component="p" variant="body2">
                        If you would like to study more than one subject from the dropdown list, please just select one and complete your application and payment, then contact us at info@melioeducation.com and we would be happy to arrange this for you.
                        </Typography>
                    </Box>
                }
            </Grid>
        </>
    )

}


export default ProductList;