export const BASE_URL =
    'https://melioeducation.my.salesforce-sites.com/developerData/services/apexrest'

const secureKeyBody = {
    key: process.env.GATSBY_SALESFORCE_KEY,
}

export default async function (service, setError) {
    const url = `${BASE_URL}/${service}/`
    try {
        const options = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(secureKeyBody),
        }

        const res = await fetch(url, options)
        const json = await res.json()
        return json
    } catch (err) {
        setError(`Failed to get ${url}`)
        return Promise.reject(err)
    }
}
