import React, { useEffect } from 'react';
import salesforceFetch from '../lib/salesforceFetch'

function useResource(service, result, setResult, setLoading, loading, setError ) {
  useEffect(() => {
    const getResourceList = async () => {
      const data = await salesforceFetch(service, setError);
      return data;
    };
    if (!result && !loading) {      
      setLoading(true)
      getResourceList().then((data) => {
        // console.log('service', service, data)
        setResult(data)
        setLoading(false)  
      })
    }
  }, [service, result, setResult, setError, setLoading, loading])
  return {
    result,
    setResult,
  }
}
export default useResource;