import React, { useState, useCallback } from "react";
import {FormControl, InputLabel, MenuItem, Select, withStyles, TextField, Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ProductList from "./ProductList";

const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: 200,
            // width: 300,
        },
    },
};

const ProductForm = (props) => {
    const { type, 
            products, 
            setSelectedProduct, 
            selectedProduct, 
            productSchemas, 
            fieldValue, 
            setField,
            duration,
            setDuration,
            whyEnabled = false
    } = props;

    const handlerPackage = useCallback((event) => {
        const value = event.target.value;
        setDuration(value)
    }, [setDuration])

    const handlerChange = (val) => {
        setSelectedProduct(val);
    }

    return (
        <>
            {
                type.oTo ? (
                    <>
                        <FormControl required fullWidth >
                            <InputLabel required>Please choose a 1:1 package</InputLabel>
                            <Select 
                                value={duration}
                                autoWidth 
                                onChange={handlerPackage} 
                                MenuProps={MenuProps}
                            >
                                {
                                    productSchemas.map((d, i) => {
                                        return <MenuItem key={d + i} value={d}>{d}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                        { duration === '' ? null :
                            <ProductList products={products} productSchemas={productSchemas} pack={duration} type={type} selectedProduct={selectedProduct} onSelectionModelChange={handlerChange}/>
                        }
                    </>
                ) : null
            }

            {type.aP ? <ProductList products={products} package={duration} type={type} onSelectionModelChange={handlerChange} selectedProduct={selectedProduct}/> : null}
            {type.upp ? <ProductList products={products} package={duration} type={type} onSelectionModelChange={handlerChange} selectedProduct={selectedProduct}/> : null}
            <Box pt={2} pb={2}>
            {(type.aP || type.oTo) && selectedProduct !== '' && whyEnabled && <TextField
                label="Please tell us why..."
                placeholder="Please tell us why you wish to study your chosen subjects. Please include any experience you may already have with your chosen subjects"
                value={fieldValue('why')}
                onChange={event => setField('why', event.target.value)}
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                required
            />}

            </Box>
            
        </>

    )
}


export default ProductForm;
