export const isOut = (age) => (age < 11)

export const isYoung = (age) => (age <= 16 && age >= 11)

export const isOld = (age) => (age >= 24 || age < 11)

export const calcAge = (val) => {
  const dob = new Date(val)
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms); 
  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export const checkDateOfBirth = (val, setError) => {
  if (val === '') setError(true);
  else setError(false);
}

