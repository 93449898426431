import React from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const getPrice = (fieldValue) => {
  const product = fieldValue('selectedProduct')
  return product.price
}

const getCourseType = (fieldValue) => {
  const type = fieldValue('productType')
  if (type === 'oTo') {
    return 'One-to-one'
  } else if (type === 'upp') {
    return 'University Preparation Program'
  } else {
    return 'Academic Online Course'
  }
}

const SummaryLine = ({label, value}) => {
  return  (
    <Grid>
        <Grid container direction="row" alignItems="center">
          <Grid xs={3} item>
            <Typography variant="body2" align="right">
              <b>{label}: </b>
            </Typography>
          </Grid>
          <Grid xs={9} item>
            <Box pl={1} m={0.5}>
              <Typography variant="body2">
                {value}
              </Typography>

            </Box>
          </Grid>
        </Grid>
    </Grid>

  )
}

const Summary = props => {
  const { fieldValue, selectedProduct } = props
  if (selectedProduct === '') return null;
  const price = getPrice(fieldValue)
  const formattedPrice = new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(price);
  const course = getCourseType(fieldValue)
  const fullName = `${fieldValue('firstName')} ${fieldValue('lastName')}`
  return (
    <>
      <Card>
          <CardContent>

              <Grid container direction="column">
                <Grid>
                  <Grid container direction="row">
                    <Grid>
                      <Typography>
                        <b>Order summary: </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box pt={1}>

                <SummaryLine label="Name" value={fullName} />
                <SummaryLine label="Course" value={course} />
                <SummaryLine label="Subject" value={selectedProduct.Subject__c} />
                <SummaryLine label="Cost" value={formattedPrice} />
                </Box>
              </Grid>
          </CardContent>
      </Card>
    </>  
  )
}

export default Summary;