import React, { useCallback, useState } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid';
import ProductForm from './ProductForm';
import { getMinForProductName } from '../hooks/useProducts'

const Products = (props) => {
  const {
    products, 
    setSelectedProduct,
    setProductType,
    selectedProduct,
    errorFor,
    productSchemas,
    fieldValue,
    setField,
    duration,
    setDuration,
    type,
    setType,
    productTypes,
    formState,
    setError,
  } = props;


  const changeType = useCallback((newType) => {
    setSelectedProduct('')
    setError('product', false)
    setField('product', newType)
    setType((prev) => {
      const next = {oTo: false, aP: false, upp: false}
      return {
        ...next,
        [newType]: true,
      }
    })
  }, [setType])

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormControl component="fieldset">
          <FormLabel error={errorFor('productType')} required component="legend">
            Please choose a Melio Product:
          </FormLabel>
          <Box display="flex" flexDirection="column" p={1} mt={1}>
            <FormGroup aria-required row={true}>
              <FormControlLabel
                control={
                <Radio checked={type.oTo}
                  type="checkbox" 
                  onChange={() => changeType('oTo')}
                  name='oTo'
                />}
                label={`One-to-One (from £ ${getMinForProductName(productTypes, 'One-to-One')})`} />

              <FormControlLabel
                control={
                  <Radio
                    checked={type.aP}
                    type="checkbox"
                    onChange={() => changeType('aP')}
                    name='aP'
                  />}
                label={`Academic Online Course (£ ${getMinForProductName(productTypes, 'Academic')})`} />
              {/* <FormControlLabel
                control={
                  <Radio
                    checked={type.upp}
                    type="checkbox"
                    onChange={() => changeType('upp')}
                    name='upp'
                  />}
                label={`University Preparation Program  (£ ${getMinForProductName(productTypes, 'University Preparation Programme')})`} /> */}
            </FormGroup>

          </Box>

        </FormControl>
        
      </Grid>
      <Grid item>
      {products && products.length ?
        <>
          <ProductForm
            formState={formState}
            setSelectedProduct={setSelectedProduct}
            selectedProduct={selectedProduct}
            products={products}
            type={type}
            productSchemas={productSchemas}
            fieldValue={fieldValue}
            setField={setField}
            duration={duration}
            setDuration={setDuration}
          />
        </>
        : null
      }
        
      </Grid>
    </Grid>

  )
} 

export default Products;
