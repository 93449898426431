import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Agreement from "./Agreement";

const UserAgreement = props => {
  const { fieldValue, setField, setError, errorFor } = props;
  const helperText = (!fieldValue('userAgreement')) ? 'Please check the agreement.' : ''
  return (
    <>
      <FormControl required error={errorFor('userAgreement')}>
        <FormControlLabel
          control={<Checkbox
            required
            checked={fieldValue('userAgreement')}
            onChange={() => {
              setField('userAgreement', !fieldValue('userAgreement'));
              setError('userAgreement', fieldValue('userAgreement'));
            }}
            name="userAgreement"
          />}
          label={<Agreement/>}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </>
  )
}

export default UserAgreement